import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useState} from "react";
import {Alert} from "@mui/material";
import '../stylesheets/LoginModal.css';

export default function FormDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [loginAlert, setLoginAlert] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleSignIn = () => {
        sendData();
    };

    const handleClose = () => {
        setOpen(false);
        setLoginAlert(false);
    };

    const sendData = () => {
        fetch('https://api.jamwizz.com/login', {
            mode: 'cors',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({username, password}),
        })
            .then(response => response.json())
            .then(data => {
                if(data['Login Status'] === 'Success') {
                    setOpen(false);
                    props.setDisplayName(data.username);
                    setLoginAlert(false);
                    setOpen(false);
                }
                else {
                    setLoginAlert(true);
                }
                props.setDisplayName(data.username);
            })
            .catch((error) => {
                console.error('Error:', error);
            }
        );
    }

    return (
        <div>
            <Button variant={"outlined"} color={'secondary'} onClick={handleClickOpen}>Login</Button>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
                <div style={{height: '48px', position: 'absolute', bottom: '48px', left: '48px'}}>
                    {loginAlert ? <Alert severity="error">Login failed, try again</Alert>: null }
                </div>
                <DialogTitle className={'uppercase text-fuchsia-900'} sx={{padding: '48px'}}>Sign In</DialogTitle>
                <DialogContent sx={{padding: '48px'}} className={loginAlert ? 'shake': null}>
                    <DialogContentText>
                        Please enter your email address and password below.
                    </DialogContentText>
                    <form>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="User name"
                            type="email"
                            fullWidth
                            variant="standard"
                            color={'secondary'}
                            value={username}
                            onChange={e => setUserName(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            id="pasword"
                            label="Password"
                            type="password"
                            fullWidth
                            variant="standard"
                            color={'secondary'}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <div className={'mt-8'}>
                            <Button disabled color={'secondary'} variant={'string'} sx={{marginLeft: '-16px'}}>Create an Account</Button>
                        </div>
                    </form>
                </DialogContent>
                <DialogActions sx={{padding: '0 48px 48px 48px'}}>
                    <Button onClick={handleClose} color={'secondary'}>Cancel</Button>
                    <Button onClick={handleSignIn} color={'secondary'} variant={'contained'}>Sign in</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}