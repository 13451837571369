import React from "react";
import Header from "../components/Header";
import Card from "../components/Card";
import { styled } from '@mui/material/styles';
import {Grid} from "@mui/material";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Footer from "../components/Footer";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function HomePage() {

    const [properties, setProperties] = React.useState([])

    const getProperties = () => {
        fetch('http://127.0.0.1:5000/get-properties', {
            mode: 'cors',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setProperties(data);
            })
            .catch((error) => {
                    console.error('Error:', error);
                }
            );
    }

    React.useEffect(() => {
        const successCallback = (position) => {
            console.log(position);
            console.log(position.coords.latitude);
            console.log(position.coords.longitude);

            const lat = position.coords.latitude;
            const lon = position.coords.longitude;

            const requestOptions = {
                method: 'GET',
            };

            fetch(`https://api.geoapify.com/v1/geocode/reverse?lat=${lat}&lon=${lon}&apiKey=677aaf3878364a4d92ebc67a097cfc1b`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    console.log(result.features[0].properties.address_line1);
                    console.log(result.features[0].properties.postcode);
                    console.log(result.features[0].properties.city);
                    console.log(result.features[0].properties.state);
                    console.log(result.features[0].properties.country);
                    console.log(result.features[0].properties.timezone.abbreviation_DST);
                })
                .catch(error => console.log('error', error));

        };

        const errorCallback = (error) => {
            console.log(error);
        };

        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
        getProperties();
    }
    , []);

    return (
        <>
            <Header/>
            <div className={'w-full flex justify-center pl-2 pr-2 mt-24 flex-wrap'}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} columns={{ xs: 2, sm: 4, md: 6, lg: 8 }}>
                        {properties.map((property, index) => (
                            <Grid item xs={2} key={index}>
                                <Item elevation={0}>
                                    <Card
                                        title={property.name}
                                        description={property.description}
                                    />
                                </Item>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </div>
            <Footer/>
        </>
    )
}