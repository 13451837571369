import React from 'react';
import './App.css';
import HomePage from "./pages/HomePage";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<div>about</div>} />
            </Routes>
        </Router>
    );
}
export default App;
