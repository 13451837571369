import React, {useState} from "react";
import {Button} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import FreeSolo from "./FreeSolo";
import MainMenu from "./MainMenu";
import '../stylesheets/Header.css';
import LoginModal from "./LoginModal";
import Snackbar from "@mui/material/Snackbar";

export default function Header() {

    const [open, setOpen] = React.useState(false);
    const [displayName, setDisplayName] = useState(undefined);
    const handleClickOpen = () => setOpen(true);
    const [isSnackOpen, setIsSnackOpen] = React.useState(false);

    return (
        <header className="App-header">
            <div className={'border-b-2 p-4 flex items-center'}>
                <div className={'flex container items-center'}>
                    <h2 className={'logo text-4xl font-bold ml-2'}>
                        <span className={'text-fuchsia-900'}>Jam</span>
                        <span className={'text-fuchsia-600'}>Wizz</span>
                    </h2>
                </div>
                <div className={'flex container items-center justify-center'}>
                    <FreeSolo/>
                </div>
                <div className={'flex container items-center justify-end'}>
                    {displayName === undefined ?
                        <div>
                            <LoginModal
                                displayName={displayName}
                                setDisplayName={setDisplayName}
                            />
                        </div> : <span className={'text-gray-500'}>Welcome back <span className={'capitalize font-semibold text-fuchsia-700'}>{displayName}</span></span>
                    }
                    <div className={'ml-4'}>
                        <Avatar/>
                    </div>
                    <div className={'ml-2 mr-4'}>
                        <MainMenu/>
                    </div>
                </div>
            </div>
            <Snackbar
                isSnackOpen={isSnackOpen}
                setIsSnackOpen={setIsSnackOpen}
            />
        </header>
    )

}